@import "@arco-design/web-react/dist/css/arco.css";

@tailwind base;
@layer base {
    svg {
        display: inline;
        vertical-align: baseline;
    }
}

@tailwind components;
@tailwind utilities;

.arco-rate-character:not(:last-child) {
    margin-right: 2px;
}

.top-user-tab-search .arco-input-tag {
    cursor: pointer;
}

.top-user-tab-search .arco-select-multiple {
    cursor: pointer;
}

/*页面滚动条样式开始*/
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background: #7B7B7B;
    border-radius: 999999px;
}
.dark ::-webkit-scrollbar-thumb {
    background: #86888B;
    border-radius: 999999px;
}
::-webkit-scrollbar {
    width: 0.4rem;
}
/* 对于Firefox */
* {
    scrollbar-width: auto;  /* 设置滚动条的宽度 */
    scrollbar-color: #7B7B7B transparent;  /* 设置滚动条滑块和滚动条的背景色 */
}
/*页面滚动条样式结束*/

/*去除选中时的篮框*/
:focus-visible {
    outline: none !important
}

.arco-switch {
    background-color: var(--color-fill-4) !important;
}
.arco-switch-checked {
    background-color: rgb(var(--primary-6)) !important;
}

.tab-animation {
    animation-name: fadeInOut;
    animation-duration: 150ms;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*多行文本省略...开始*/
.u-ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.u-ellipsis-2 {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.u-ellipsis-3 {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.multirow-ellipsis-2 {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.multirow-ellipsis-3 {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.multirow-ellipsis-4 {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

/*多行文本省略...结束*/

.bilibilinum .start {
    stop-color: #7b859a
}

.bilibilinum .end {
    stop-color: #7b859a
}

.bilibilinum0 .start {
    stop-color: #fffd8c
}

.bilibilinum0 .end {
    stop-color: #ffa30d
}

.bilibilinum1 .start {
    stop-color: #c2f4ff
}

.bilibilinum1 .end {
    stop-color: #88a5d2
}

.bilibilinum2 .start {
    stop-color: #ffe4c7
}

.bilibilinum2 .end {
    stop-color: #ed9487
}


/* 隐藏滑动条开始*/
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* 隐藏滑动条结束*/

.weibo-topic-desc .icon-link {
    width: 1em;
    height: 1em;
    margin-right: 2px;
    vertical-align: -2px;
    -webkit-filter: sepia(100%) saturate(38%) contrast(75%);
    filter: sepia(100%) saturate(38%) contrast(75%);
}

.weibo-topic-desc img {
    display: inline-block;
    margin-left: 2px;
    width: 18px;
    height: 18px;
    vertical-align: -4px;
}

.weibo-topic-desc br {
    content: ' '
}

.weibo-topic-desc br:after {
    content: ' '
}

.douyin-top1 {
    background: linear-gradient(106.69deg, #ffedbd, #ffc83a 44.27%, #ff7a00)
}

.douyin-top2 {
    background: linear-gradient(106.69deg, #ffcdbd, #f48a7b 44.27%, #e2755c)
}

.douyin-top-default {
    background: linear-gradient(106.69deg, #d0d5e6, #9ea3b7 44.27%, #949dbc)
}

.wwads-text {
    color: inherit !important;
}

body[arco-theme='dark'] {
    --color-menu-light-bg: "#161B22"
}

.markdown-light-mode {
    @import "@github-markdown-css/github-markdown.css";
}

.markdown-dark-mode {
    @import '@github-markdown-css/github-dark-markdown.css';
}