.dark .markdown-navbar-body {
    --color-active-bg: #2a2a2b;
    --color-font-active: rgba(255,255,255,0.9);
    --color-font-level1: rgba(255,255,255,0.7);
    --color-font-level2: rgba(255,255,255,0.7);
    --color-font-level3: rgba(255,255,255,0.7);
    --color-font-level4: rgba(255,255,255,0.7);
    --color-font-level5: rgba(255,255,255,0.7);
    --color-font-level6: rgba(255,255,255,0.7);

}

.markdown-navbar-body{
    --color-active-bg: #F2F3F5;
    --color-font-active: #1d2129;
    --color-font-level1: #4e5969;
    --color-font-level2: #4e5969;
    --color-font-level3: #4e5969;
    --color-font-level4: #4e5969;
    --color-font-level5: #4e5969;
    --color-font-level6: #4e5969;
}

.markdown-navigation {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    width: 100%;
    overflow: hidden;
}

.markdown-navigation .title-anchor {
    display: block;
    transition: all 0.2s;
    margin: 0.3em 0;
    /*font-weight: lighter;*/
    line-height: 1.4em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    padding-right: 1.8em;
    cursor: pointer;
    border-radius: 0.375rem;
}

.markdown-navigation .title-anchor:hover,
.markdown-navigation .title-anchor.active {
    background-color: var(--color-active-bg);
    text-decoration: inherit;
}

.markdown-navigation .title-anchor.active {
    color: var(--color-font-active);
}

.markdown-navigation .title-anchor small {
    margin: 0 0.8em;
    font-size: 100%;
}

.markdown-navigation .title-level1 {
    color: var(--color-font-level1);
    font-size: 1.2em;
    padding-left: 1em;
    font-weight: normal;
}

.markdown-navigation .title-level2 {
    color: var(--color-font-level2);
    font-size: 1em;
    padding-left: 1em;
    font-weight: normal;
}

.markdown-navigation .title-level3 {
    color: var(--color-font-level3);
    font-size: 0.9em;
    padding-left: 3em;
    font-weight: normal;
}

.markdown-navigation .title-level4 {
    color: var(--color-font-level4);
    font-size: 0.8em;
    padding-left: 5em;
}

.markdown-navigation .title-level5 {
    color: var(--color-font-level5);
    font-size: 0.8em;
    padding-left: 7em;
}

.markdown-navigation .title-level6 {
    color: var(--color-font-level6);
    font-size: 0.8em;
    padding-left: 9em;
}